import React, { useState } from "react";
import FormContainer from "../components/FormContainer";
import { Form, FormControl, FormLabel } from "react-bootstrap";
import ReactPlayer from "react-player";

const YoutubeScreen = () => {
  const [url, setUrl] = useState("");

  return (
    <FormContainer>
      <Form>
        <FormLabel>Youtube</FormLabel>
        <FormControl
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        ></FormControl>
      </Form>
      {url}
      <ReactPlayer
        playing
        width="100%"
        height="100%"
        light={
          <img
            width="100%"
            height="100%"
            src="https://res.cloudinary.com/aymanhamoda/image/upload/v1689163591/1684956729811_kwux7a.jpg"
          />
        }
        controls
        url={url && url}
      />
    </FormContainer>
  );
};

export default YoutubeScreen;
